










































import { Component, Prop, Vue } from 'vue-property-decorator'

import { ImageProps } from '../../../../dsl/atoms/Image'
import { Price as ProductPrice, ProductData, Variant } from '../../../../contexts'
import { toImageProps } from '../../../../front/shared/support'

import Price from '../../../../front/shared/molecules/Price/Price.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<UsageCalculatorProductCard>({
  name: 'UsageCalculatorProductCard',
  components: { Price },
  mounted () {
    this.variant = this.product ? Object.values(this.product.variants)[0] : null
    this.calculatePrices()
  }
})
export class UsageCalculatorProductCard extends Vue {
  @Prop({ type: Number, required: true })
  public readonly count!: number

  @Prop({ type: Boolean, required: false, default: true })
  public readonly isPriceVisible!: boolean

  @Prop({ type: Object, required: true })
  public readonly product!: ProductData | null

  public variant: Variant<string> | null = null
  public total: Partial<ProductPrice> | null = null

  public get image (): ImageProps | null {
    if (!this.variant) {
      return null
    }

    return toImageProps(this.variant.images[0])
  }

  public calculatePrices () {
    if (!this.variant) {
      return
    }

    this.total = {
      finalPrice: this.variant.price.finalPrice * this.count,
      finalPriceNet: this.variant.price.finalPriceNet * this.count
    }
  }
}

export default UsageCalculatorProductCard
