


import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'HasSiteOverride'
})
export class HasSiteOverride extends Vue {
  public get siteId (): number | undefined {
    const result = this.$store.getters['content/response']

    if (result === null || !result.content?.properties ||
      !('product-config' in result.content.properties)) {
      return
    }

    return result.content.properties['product-config']?.siteId
  }
}

export default HasSiteOverride
