







































import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'

import { TabItem } from '../../../../dsl/atoms/TabsNav'
import { TabsContainersRegistry } from '../../../../dsl/molecules/Tabs'

import {
  UsageCalculatorModuleContent,
  UsageCalculatorProduct,
  UsageCalculatorProductResult,
  UsageCalculatorProductSelection,
  UsageCalculatorTab
} from '../../UsageCalculator.contracts'
import UsageCalculatorProductCard from './UsageCalculatorProductCard.vue'
import { ProductData } from '../../../../contexts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<UsageCalculatorProducts>({
  name: 'UsageCalculatorProducts',
  components: { UsageCalculatorProductCard },
  mounted () {
    this.$nextTick(() => {
      if (this.refFlexTab && this.refFixTab) {
        this.containers = {
          [UsageCalculatorTab.Flex]: this.refFlexTab,
          [UsageCalculatorTab.Fix]: this.refFixTab
        }
      }
    })
    this.changeTab()
  }
})
export class UsageCalculatorProducts extends Vue {
  @Prop({ type: Boolean, required: true })
  public readonly canBuy!: boolean

  @Prop({ type: Object, required: true })
  public readonly usage!: UsageCalculatorProductResult

  @Prop({ type: Object, required: true })
  public readonly products!: UsageCalculatorModuleContent['products']

  @PropSync('selected', { type: Object, required: false })
  public _selected!: UsageCalculatorProductSelection

  @Ref('flex')
  private readonly refFlexTab!: NodeList

  @Ref('fix')
  private readonly refFixTab!: NodeList

  public UsageCalculatorTab = UsageCalculatorTab

  public activeTab: UsageCalculatorTab = UsageCalculatorTab.Flex
  public containers: TabsContainersRegistry | null = null

  public selectedVariants: UsageCalculatorProduct[] | null = null

  public readonly tabs: TabItem[] = [
    {
      label: this.$t('modules.UsageCalculator.results.tabs.flex').toString(),
      containerId: UsageCalculatorTab.Flex,
      className: 'UsageCalculator__results__products__tab--flex'
    },
    {
      label: this.$t('modules.UsageCalculator.results.tabs.fix').toString(),
      containerId: UsageCalculatorTab.Fix,
      className: 'UsageCalculator__results__products__tab--fix'
    }
  ]

  public readonly productsStructure = {
    [UsageCalculatorTab.Flex]: [
      [
        UsageCalculatorProduct.WinsFlexStrefaZewnetrznaWiadro,
        UsageCalculatorProduct.WinsFlexStrefaZewnetrznaKielbasa
      ],
      UsageCalculatorProduct.WinsFlexStrefaIzolacji,
      [
        UsageCalculatorProduct.WinsFlexStrefaWewnetrznaWiadro,
        UsageCalculatorProduct.WinsFlexStrefaWewnetrznaKielbasa
      ]
    ],
    [UsageCalculatorTab.Fix]: [
      [
        UsageCalculatorProduct.WinsFixStrefaZewnetrzna37,
        UsageCalculatorProduct.WinsFixStrefaZewnetrzna712,
        UsageCalculatorProduct.WinsFixStrefaZewnetrzna1018
      ],
      UsageCalculatorProduct.WinsFixStrefaIzolacji,
      [
        UsageCalculatorProduct.WinsFixStrefaWewnetrznaWiadro,
        UsageCalculatorProduct.WinsFixStrefaWewnetrznaKielbasa
      ]
    ]
  }

  @Watch('usage')
  protected onUsageUpdate (): void {
    this.updateSelection()
  }

  @Watch('activeTab')
  public changeTab () {
    this.selectedVariants = this.productsStructure[this.activeTab].map(item => typeof item === 'string'
      ? item : item[0])
    this.updateSelection()
  }

  public changeVariant (index: number, id: UsageCalculatorProduct) {
    if (!this.selectedVariants) {
      return
    }

    this.selectedVariants = this.selectedVariants.map((item, i) => i === index ? id : item)
    this.updateSelection()
  }

  public updateSelection () {
    if (!this.selectedVariants) {
      return
    }

    this._selected = this.selectedVariants.reduce((acc, id) => {
      if (!this.products) {
        return acc
      }

      const product: ProductData | null = this.products[id]

      if (!product || !product.sku) {
        return acc
      }

      return {
        ...acc,
        [product.sku]: this.usage[id]
      }
    }, {})
  }
}

export default UsageCalculatorProducts
