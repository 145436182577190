















import { Component, Prop, Vue } from 'vue-property-decorator'

import { UsageCalculatorTotalResult } from '../../UsageCalculator.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<UsageCalculatorTotals>({
  name: 'UsageCalculatorTotals'
})
export class UsageCalculatorTotals extends Vue {
  @Prop({ type: Object, required: true })
  public readonly total!: UsageCalculatorTotalResult

  private fields: (keyof UsageCalculatorTotalResult)[] = ['outside', 'insulation', 'inside']

  public get formattedTotals () {
    return this.fields.map((name) => {
      return { name, value: this.formatValue(this.total[name]) }
    })
  }

  private formatValue (value: number): string {
    return value.toFixed(2).replace('.', ',') + ' l'
  }
}

export default UsageCalculatorTotals
