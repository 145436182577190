


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { Price as ProductPrice } from '../../../../contexts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<Price>({ name: 'Price' })
export class Price extends Vue {
  @Prop({ type: Object, required: true })
  public price!: ProductPrice

  @Prop({ type: Boolean, required: true })
  public shouldDisplayNetto!: boolean

  @Prop({ type: Number, required: false })
  public promoPrice!: number

  @Prop({ type: [String, Object], required: false })
  public classNameBrutto!: string | AnyObject

  @Prop({ type: [String, Object], required: false })
  public classNameFinal!: string | AnyObject

  public formatPrice (price: number): string {
    return this.$options.filters?.currency(price) ?? price.toString()
  }
}

export default Price
