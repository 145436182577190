






































































import { Component, Mixins } from 'vue-property-decorator'

import { BaseCartMixin, IBaseCart } from '../../../front/checkout/shared/mixins/base-cart.mixin'

import { AbstractModuleUi } from '../../abstract/ui'
import {
  CartAddedItemData,
  CartServiceType,
  ICartService
} from '../../../front/checkout/services/cart'
import { ImageProps } from '../../../dsl/atoms/Image'
import { Inject } from '../../../support'
import { toImageProps } from '../../../front/shared/support'

import {
  UsageCalculatorModule,
  UsageCalculatorProduct, UsageCalculatorProductResult, UsageCalculatorProductSelection,
  UsageCalculatorResult,
  UsageCalculatorTotalResult
} from '../UsageCalculator.contracts'
import UsageCalculatorTotals from './partials/UsageCalculatorTotals.vue'
import UsageCalculatorProducts from './partials/UsageCalculatorProducts.vue'
import { IToastsService, ToastsServiceType, ToastType } from '../../../front/shared/services'
import { ISiteService, ProductData, SiteServiceType, Variant } from '../../../contexts'
import {
  IProductsRepository,
  IProductStockRepository,
  ProductsRepositoryType,
  ProductStockRepositoryType
} from '../../../front/products/contracts/repositories'
import {
  IUsageCalculatorService,
  UsageCalculatorServiceType
} from '../../../front/shared/services/calculator'
import { considerStockAvailabilityAndPackages } from '../../../front/products/helpers/product-quantity-reducer'
import HasSiteOverride from '../../../support/mixins/HasSiteOverride.mixin.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<UsageCalculatorModuleUi>({
  name: 'UsageCalculatorModuleUi',
  components: { UsageCalculatorProducts, UsageCalculatorTotals },
  mounted () {
    this.calculate()
  }
})
export class UsageCalculatorModuleUi extends Mixins<AbstractModuleUi<UsageCalculatorModule>,
  IBaseCart, HasSiteOverride>(AbstractModuleUi, BaseCartMixin, HasSiteOverride) {
  @Inject(CartServiceType)
  public readonly cartService!: ICartService

  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(ProductStockRepositoryType)
  private productStockRepository!: IProductStockRepository

  @Inject(ToastsServiceType, false)
  private readonly toastService?: IToastsService

  @Inject(UsageCalculatorServiceType, false)
  private readonly usageCalculatorService?: IUsageCalculatorService

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  public depth: number | null = null
  public isLoading: boolean = false
  public length: number | null = null
  public selected: UsageCalculatorProductSelection | null = null
  public result: UsageCalculatorResult | null = null
  public width: number | null = null
  private readonly units = {
    mb: this.$t('modules.UsageCalculator.unit.mb').toString(),
    mm: this.$t('modules.UsageCalculator.unit.mm').toString(),
  }

  private usagePerMb: Record<UsageCalculatorProduct, number> = {
    [UsageCalculatorProduct.WinsFlexStrefaZewnetrznaWiadro]: 1000 / 2400,
    [UsageCalculatorProduct.WinsFlexStrefaZewnetrznaKielbasa]: 1000 / 600,
    [UsageCalculatorProduct.WinsFlexStrefaIzolacji]: 1 / 18.96,
    [UsageCalculatorProduct.WinsFlexStrefaWewnetrznaWiadro]: 1000 / 2400,
    [UsageCalculatorProduct.WinsFlexStrefaWewnetrznaKielbasa]: 1000 / 600,
    [UsageCalculatorProduct.WinsFixStrefaZewnetrzna37]: 1 / 32,
    [UsageCalculatorProduct.WinsFixStrefaZewnetrzna712]: 1 / 32,
    [UsageCalculatorProduct.WinsFixStrefaZewnetrzna1018]: 1 / 16,
    [UsageCalculatorProduct.WinsFixStrefaIzolacji]: 1 / 18.96,
    [UsageCalculatorProduct.WinsFixStrefaWewnetrznaWiadro]: 1000 / 2400,
    [UsageCalculatorProduct.WinsFixStrefaWewnetrznaKielbasa]: 1000 / 600
  }

  public get canBuy (): boolean {
    return !this.data.content.isPreview
  }

  public get emptyImage (): ImageProps | undefined {
    if (!this.data.content.image) {
      return
    }

    return toImageProps(this.data.content.image)
  }

  public get isReady (): boolean {
    return !!this.length && !!this.width && !!this.depth
  }

  public get cartLink () {
    if (!this.siteService || !this.siteService.getProperty('cartPageUrl')) {
      return ''
    }

    return this.siteService.getProperty('cartPageUrl')
  }

  public async addToCart (): Promise<void> {
    if (!this.selected) {
      return
    }

    const cartId = this.$store.getters['checkout/cartId']

    try {
      const addedProducts: CartAddedItemData[] = Object.entries(this.selected)
        .map(([sku, quantity]) => ({ sku, quantity }))

      const candidateResolvedVariants = UsageCalculatorModuleUi.getResolvedProductsVariants(this.data.content.products)
      const candidateProducts = await considerStockAvailabilityAndPackages(
        this.isUserB2B,
        addedProducts,
        this.productStockRepository,
        this.productsRepository,
        candidateResolvedVariants
      )

      const cart = await this.cartService.addManyToCart(cartId, candidateProducts)
      this.refreshCart(cart)

      this.toastService?.show(this.$t('modules.UsageCalculator.addedToCart').toString(),
        ToastType.Success)

      if (this.cartLink) {
        window.location.href = this.cartLink as string
      }
    } catch (e) {
      this.toastService?.show((e as Error).message, ToastType.Danger)
    }
  }

  public calculate (): void {
    if (!this.data.content.products || !this.length || !this.width || !this.depth) {
      this.result = null
      return
    }

    const total = {
      inside: this.width * this.length * 0.00394,
      insulation: (this.width * this.length * this.depth) / 1000,
      outside: this.width * this.length * 0.0031,
      bands: 1.1 * this.length
    }

    this.result = {
      total,
      productUsage: Object.keys(this.data.content.products).reduce((acc, key) => {
        const usage = this.calculateProductUsage(key as UsageCalculatorProduct, total) ?? 0

        return {
          ...acc,
          [key]: usage
        }
      }, {} as UsageCalculatorProductResult)
    }
  }

  public async download (): Promise<void> {
    if (!this.result || !this.data.content.products) {
      return
    }

    this.isLoading = true

    const payload = {
      input: {
        width: `${this.width} ${this.units.mm}`,
        length: `${this.length} ${this.units.mb}`,
        depth: `${this.depth} ${this.units.mm}`
      },
      productUsage: Object.entries(this.result.productUsage).reduce((acc, [key, usage]) => {
        if (!this.data.content.products) {
          return acc
        }

        const product = this.data.content.products[key as UsageCalculatorProduct]

        if (!product?.sku) {
          return acc
        }

        return {
          ...acc,
          [product.id]: this.$tc('modules.UsageCalculator.results.pieces', usage, { count: usage }).toString()
        }
      }, {}),
      total: Object.entries(this.result.total).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: value.toFixed(2).replace('.', ',') + ' l'
        }
      }, {})
    }

    try {
      await this.usageCalculatorService?.getPdf(payload, this.siteId, this.data.content.isPreview)
    } finally {
      this.isLoading = false
    }
  }

  private calculateProductUsage (id: UsageCalculatorProduct, totalUsage: UsageCalculatorTotalResult) {
    let usage: number

    switch (id) {
      case UsageCalculatorProduct.WinsFixStrefaWewnetrznaKielbasa:
      case UsageCalculatorProduct.WinsFlexStrefaWewnetrznaKielbasa:
      case UsageCalculatorProduct.WinsFixStrefaWewnetrznaWiadro:
      case UsageCalculatorProduct.WinsFlexStrefaWewnetrznaWiadro:
        usage = totalUsage.inside * this.usagePerMb[id]
        break

      case UsageCalculatorProduct.WinsFlexStrefaZewnetrznaKielbasa:
      case UsageCalculatorProduct.WinsFlexStrefaZewnetrznaWiadro:
        usage = totalUsage.outside * this.usagePerMb[id]
        break

      case UsageCalculatorProduct.WinsFixStrefaIzolacji:
      case UsageCalculatorProduct.WinsFlexStrefaIzolacji:
        usage = totalUsage.insulation * this.usagePerMb[id]
        break

      case UsageCalculatorProduct.WinsFixStrefaZewnetrzna37:
      case UsageCalculatorProduct.WinsFixStrefaZewnetrzna712:
      case UsageCalculatorProduct.WinsFixStrefaZewnetrzna1018:
        usage = totalUsage.bands * this.usagePerMb[id]
        break
    }

    return Math.ceil(usage)
  }

  private static getResolvedProductsVariants (products: Record<string, ProductData | null> | null): Array<Variant<string>> {
    if (!products) {
      return []
    }

    const resolvedVariantsMap = new Map()

    if (products && Object.keys(products).length > 0) {
      for (const product of Object.values(products)) {
        if (!product) {
          break
        }

        for (const variant of Object.values(product.variants)) {
          if (resolvedVariantsMap.has(variant.sku)) {
            break
          }

          resolvedVariantsMap.set(variant.sku, variant)
        }
      }
    }

    if (resolvedVariantsMap.size === 0) {
      return []
    }

    return [...resolvedVariantsMap.values()]
  }
}

export default UsageCalculatorModuleUi
